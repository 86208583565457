<template>
  <div class="px-10" data-testid="trial-blank-form-page">
    <Breadcrumbs />
    <v-row>
      <v-col class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">TRIAL {{ trialNumber }}</span>
        </div>

        <h4 class="page-title" data-testid="trial-title">Blank form</h4>
        <slot name="sub-header" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="rounded-lg">
          <v-data-table
            data-testid="trial-blank-form-data-table"
            :headers="headers"
            :items="items"
            @click:row="() => {}"
            class="rounded-lg"
            :hide-default-header="!hasData"
            :class="{ 'no-data-table': !hasData && !isLoading }"
            :hide-default-footer="!hasData && !isLoading"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions,
            }"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-row no-gutters v-if="hasData || isLoading">
                <v-col>
                  <v-data-footer
                    data-testid="trial-blank-form-top-pagination"
                    :pagination="pagination"
                    :options="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    :items-per-page-options="itemsPerPageOptions"
                    class="top-footer-item"
                  />
                </v-col>
              </v-row>
            </template>
            <template #item="{ item }">
              <tr>
                <td class="text-no-wrap">
                  <span class="pr-1">
                    <v-icon small color="black">mdi-paperclip</v-icon></span
                  >
                  <span class="hidden-sm-and-down">{{ item.fileName }}</span>
                  <span class="hidden-md-and-up"
                    >..{{
                      item.fileName.substring(
                        item.fileName.lastIndexOf('_') + 1,
                        item.fileName.length
                      )
                    }}</span
                  >
                </td>
                <td>
                  {{ item.fileSize ? item.fileSize : '-' }}
                </td>
                <td>
                  <DateFormat
                    :value="item.requestedAt.time"
                    data-testid="item-requested-date"
                  />
                </td>
                <td><ArchiveStatus :item="item" /></td>
                <td>
                  <template v-if="item.status === 'created'">
                    <v-tooltip max-width="300" bottom v-if="!isDeviceSupported">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="secondary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="mr-2"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span
                        ><strong>Download is not possible from an iPad.</strong>
                        Please change to a PC to download
                      </span>
                    </v-tooltip>
                    <DownloadBlankForm
                      :disabled="!isDeviceSupported"
                      :item="item"
                      :siteNo="0"
                      @file-downloaded="loadBlankForms"
                    />
                  </template>
                </td>
                <td class="text-right pr-6">
                  <BlankFormAuditDialog :item="item" />
                </td>
              </tr>
            </template>
            <template #no-data>
              <template v-if="isLoading">Loading... Please wait</template>

              <div v-else class="py-6">
                <div class="d-flex align-center justify-center mx-auto my-4">
                  <ImgBlankFormLarge />
                </div>

                <strong class="no-data-text d-block mb-4">
                  The blank form is not ready for download
                </strong>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import ArchiveStatus from '@/components/archive/ArchiveStatus.vue'
import BlankFormAuditDialog from '@/components/blank-form/BlankFormAuditDialog.vue'
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue'
import DateFormat from '@/components/DateFormat.vue'
import DownloadBlankForm from '@/components/blank-form/DownloadBlankForm.vue'

import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import loadingState from '@/constants/loadingState'
import blankFormService from '@/services/blank-form-service'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import deviceInfo, { simulateIpad } from '@/utils/deviceInfo'
import globalLoadingActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'

export default {
  name: 'TrailBlankForm',
  mixins: [trialSettingsGettersMixin, globalLoadingActionsMixins],
  components: {
    Breadcrumbs,
    DownloadBlankForm,
    BlankFormAuditDialog,
    DateFormat,
    ImgBlankFormLarge: () =>
      import('@/components/images/ImgBlankFormLarge.vue'),
    ArchiveStatus,
  },
  data() {
    return {
      isDeviceSupported: false,
      itemsPerPageOptions,
      state: loadingState.INITIAL,
      items: [],
      isMounted: false,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    hasData() {
      return this.items.length > 0
    },
    headers() {
      return [
        {
          text: 'File name',
          sortable: false,
        },
        {
          text: 'File size',
          sortable: false,
          width: '80px',
        },
        {
          text: 'Upload date',
          sortable: false,
          width: '120px',
        },
        {
          text: 'Status',
          sortable: false,
        },
        { text: '', sortable: false },
        { text: '', sortable: false },
      ]
    },
  },
  methods: {
    loadBlankForms() {
      if (this.state === loadingState.INITIAL) this.state = loadingState.LOADING

      blankFormService
        .getTrialsBlankForms()
        .then(response => {
          if (this.isMounted) {
            this.state = loadingState.LOAD_SUCCEEDED
            this.items = response.blankForms
          }
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.loadBlankForms()
    this.isDeviceSupported = simulateIpad ? true : !deviceInfo.isIpadAir2019()
  },
  mounted() {
    this.isMounted = true
  },
  beforeDestroy() {
    this.isMounted = false
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  color: $nn-D_T20;
}

.v-data-table {
  .top-footer-item {
    border-top: none;
  }
  ::v-deep thead th {
    background-color: $nn-TB_T98;
    letter-spacing: -0.02em;
    color: $nn-D_T20 !important;
    height: 37px !important;
    border-bottom: none !important;
    > span {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
}
</style>
